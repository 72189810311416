module.exports={
    "animation": {
        "autoplay": true
    },
    "apiUrlBase": "https://development.api.jthelander.com/complander",
    "apiVersion": "",
    "assetsUrlBase": "https://development.complander.com/20240315_2115_1274272001dcc7f6d144857176dbbd462e20223c/mothership/assets",
    "blurString": "$◼︎◼︎◼︎",
    "cdnUrlBase": "https://development.complander.com/20240315_2115_1274272001dcc7f6d144857176dbbd462e20223c",
    "mobileWidth": 767,
    "mockdata": {
    },
    "showApiHint": true
}
